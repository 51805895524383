<template>
    <div class="page">
        <div class="page-header">
            <div style="float:right;display:flex;padding-bottom: 10px">
                <buttonImportExpert style="margin-right:10px" @close="getData"  class="hidden-xs-only hidden-sm-only hidden-md-only"/>
                <el-button size="mini" @click="handleExport" icon="el-icon-download" type="">导出</el-button>
                <el-button size="mini" @click="onAdd('')" icon="el-icon-plus" type="primary">添加</el-button>
                <el-button size="mini" @click="getData" icon="el-icon-refresh"  class="hidden-xs-only hidden-sm-only hidden-md-only">刷新</el-button>
                <el-button size="mini" @click="searchVisible=!searchVisible" icon="el-icon-search">搜索</el-button>
                <el-button size="mini" @click="showAsTable = !showAsTable" icon="el-icon-set-up" class="hidden-xs-only hidden-sm-only hidden-md-only">{{ showAsTable ? '卡片' : '列表' }}</el-button>
            </div>
            <el-page-header @back="$router.go(-1)" content="专家库" />
        </div>
        <div class="page-search" v-if="searchVisible">
            <el-form :inline="true" :model="search" size="small">
                <el-form-item label="分类">
                    <selectExpertCategory v-model="search.category" />
                </el-form-item>
                <el-form-item label="科室">
                    <selectExpertDepartment v-model="search.department" />
                </el-form-item>
                <el-form-item label="专业">
                    <selectExpertMajor v-model="search.major" />
                </el-form-item>
                <el-form-item label="职称">
                    <selectExpertTitle v-model="search.title" />
                </el-form-item>
                <el-form-item label="姓名">
                    <el-input v-model="search.name" placeholder="请输入姓名" clearable />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" plain @click="handleSearch" :loading="loading">查询</el-button>
                    <el-button type="default" plain @click="handleExport" :loading="loading">导出</el-button>
                </el-form-item>
            </el-form>
        </div>
        <div class="main-content"  v-if="showAsTable">
            <div class="table-content">
                <el-table :data="table.data" border stripe size="mini" v-loading="loading" width="100%" height="100%"
                          :header-cell-style="{ backgroundColor: '#FAFAFA' }" @row-click="handleRowChange">
                    <el-table-column type="index" label="序号" align="center"
                                     :index="(index) => { return (this.table.page - 1) * this.table.pageSize + index + 1 }" width="55" />
                    <el-table-column label="姓名" prop="name" width="" show-overflow-tooltip/>
                    <el-table-column label="分类" prop="category" width="" show-overflow-tooltip/>
                    <el-table-column label="科室" prop="department" width="" show-overflow-tooltip/>
                    <el-table-column label="职称" prop="title" width="" show-overflow-tooltip/>
                    <el-table-column label="电话" prop="contact" width="" show-overflow-tooltip/>
                    <el-table-column label="专业" prop="major" width="" show-overflow-tooltip/>
                    <el-table-column label="学历" prop="education" width="" show-overflow-tooltip/>
                    <el-table-column label="简介" prop="summary" width="" show-overflow-tooltip/>
                    <el-table-column label="创建时间" prop="createdAt"  width="" show-overflow-tooltip/>
                </el-table>
            </div>
        </div>
        <el-row class="page-main" :gutter="10" v-loading="loading" v-else>
            <el-col :xs="24" :sm="12" :md="12" :lg="6" :xl="6" v-for="(item, index) in table.data" :key="index">
                <el-card shadow="hover" style="margin-bottom: 10px">
                    <div class="expert-card" @click="onAdd(item.id)">
                        <el-image class="img" :src="item.image">
                            <div slot="error" class="image-slot">
                                <img src="../../assets/none.png" class="img">
                            </div>
                        </el-image>
                        <div class="content">
                            <div class="nowrap">姓名：{{ item.name }}</div>
                            <div class="nowrap">科室：{{ item.department }}</div>
                            <div class="nowrap">专业：{{ item.major }}</div>
                            <div class="nowrap">职称：{{ item.title }}</div>
                            <div class="nowrap">电话：{{ item.contact }}</div>
                        </div>
                    </div>
                </el-card>
            </el-col>
        </el-row>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50, 100, 200, 500, 1000]"
                :current-page="table.page" :total="table.total" :page-size="table.pageSize"
                @current-change="handlePageChange" @size-change="handlePageSizeChange" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            loading: false,
            searchVisible: false,
            search: { category: [], department: [], major: [], title: [] },
            showAsTable: true,
            table: {
                data: [],
                total: 0,
                page: 1,
                pageSize: 20
            }
        }
    },
    methods: {
        onAdd(id) {
            this.$router.push({
                path: `/main/expert/form/${id}`,
            })
        },
        handleRowChange(row, column){
            this.$router.push({
                path: `/main/expert/form/${row.id}`,
            })
        },
        handleSearch() {
            this.table.page = 1
            this.getData()
        },
        handlePageChange(e) {
            this.table.page = e
            this.getData()
        },
        handlePageSizeChange(e) {
            this.table.pageSize = e
            this.getData()
        },
        async handleExport() {
            this.loading = true
            try {
                let params = this.search
                params.export = 'xlsx'//'csv'
                const resp = await this.$http.get(`/expert?page=${this.table.page}&pageSize=${this.table.pageSize}`, { params: params, responseType: 'blob' })
                let blob = new Blob([resp.data], { type: 'application/vnd.openxmlformats;charset=utf-8;' })
                var link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = decodeURI(escape(resp.headers['content-disposition'].split('=')[1]))
                link.click()
                window.URL.revokeObjectURL(link.href)
            } catch (e) {
                this.$message.error(e.message)
            }
            this.loading = false
        },
        async getData() {
            this.loading = true
            const resp = await this.$http.get(`/expert?page=${this.table.page}&pageSize=${this.table.pageSize}&status=1`, { params: this.search })
            if (!resp.data) {
                this.$message.error(resp.data.msg)
            } else {
                this.table.data = resp.data.rows
                this.table.total = resp.data.count
            }
            this.loading = false
        }
    },
    mounted() {
        this.getData()
    }
}
</script>

<style lang="scss" scoped>
.page {
    display: flex;
    flex-direction: column;
    height: 100%;

    .page-search {
        margin: 15px 0;
        padding: 5px;
        background-color: #FAFAFA;
        border: 1px solid #EBEEF5;

        ::v-deep .el-form-item {
            padding: 0 !important;
            margin: 5px 10px !important;
        }
    }

    .page-main {
        flex: 1;
        overflow: scroll;
    }

    .page-footer {
        background-color: #FAFAFA;
        border: 1px solid #EBEEF5;
        padding: 10px;
        text-align: center;
        el-pagination{
            overflow: hidden;
        }
    }
}

.main-content {
    flex: 1;
    position: relative;
}

.table-content {
    position: absolute;
    width: 100%;
    height: 100%;
}

.expert-card {
    display: flex;
    cursor: pointer;
    align-items: center;
    .img {
        width: 100px;
        height: 140px;
        text-align: center;
        border-radius: 3px;
    }

    .content {
        flex: 1;
        padding: 0 10px;
        line-height: 26px;
        overflow: hidden;
        /* 超出部分隐藏 */
        text-overflow: ellipsis;
    }
}

    .nowrap {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    ::v-deep .el-table__row{
        cursor: pointer;
    }
</style>
